// if (document.getElementById('hero-text-container')) {
//     var textPath = document.querySelector('#hero-text-path');

//     var textContainer = document.querySelector('#hero-text-container');

//     var path = document.querySelector( textPath.getAttribute('href') );

//     var pathLength = path.getTotalLength();
//     // console.log(pathLength);

//     function updateTextPathOffset(offset){
//         textPath.setAttribute('startOffset', offset); 
//     }

//     updateTextPathOffset(pathLength);

//     function animateHero(){
//         requestAnimationFrame(function(){
//             var rect = textContainer.getBoundingClientRect();
//             var scrollPercent = rect.y / window.innerHeight;
//             console.log(scrollPercent);
//             updateTextPathOffset( (scrollPercent * 2 * pathLength) - 120 );
//         });
//     }

//     window.addEventListener('scroll',animateHero);

//     window.addEventListener('DOMContentLoaded',() => {
//         animateHero();
//     });
// }