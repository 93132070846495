if(document.getElementsByClassName('RecipesPage').length > 0) {

    // var macy = Macy({
    //     container: '#macy-grid',
    //     trueOrder: false,
    //     waitForImages: false,
    //     margin: 24,
    //     columns: 4,
    //     breakAt: {
    //         1200: 3,
    //         768: 1
    //     }
    // });

    // var elem = document.querySelector('.grid');
    // var msnry = new Masonry( elem, {
    //     itemSelector: '.grid-item',
    //     columnWidth: '.grid-sizer',
    //     percentPosition: true
    // });

}