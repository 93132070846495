if(document.getElementsByClassName('reviews-container').length > 0) {

    document.getElementById('btn-load-reviews').addEventListener('click', (e) => {
        e.preventDefault();
        let product = e.target.dataset.product;
        let offset = e.target.dataset.offset;
        const reviewContainer = document.getElementById('reviews-container');
        fetch(`/ajax/reviews/${product}/${offset}`).then(function (response) {
            return response.text();
        }).then(function (html) {
            reviewContainer.innerHTML += html;
            e.target.dataset.offset = parseInt(offset) + 4;
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });    
    });

    // document.querySelectorAll('.btn-get-reviews').forEach((btn) => {
    //     btn.addEventListener('click', (e) => {
    //         e.preventDefault();
    //         const reviewContainer = document.getElementById('reviews-container');
    //         fetch('/ajax/reviews').then(function (response) {
    //             return response.text();
    //         }).then(function (html) {
    //             reviewContainer.innerHTML = html;
    //             reviewContainer.classList.add('loaded');
    //         }).catch(function (err) {
    //             console.warn('Something went wrong.', err);
    //         });
    
    //     });
    // });
}