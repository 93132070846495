if(document.getElementsByClassName('ScrollingVideoElement').length > 0) {

    var videoElement = document.querySelector('.ScrollingVideoElement');
    var cursor = document.querySelector('.cursor');
    let videoWrapper = document.getElementById('video-preview-wrapper');
    var videoWrapperWidth = 50;
    var choosePath = document.querySelector('#curve-text-choose');
    var happyPath = document.querySelector('#curve-text-happy');
    var textContainer = document.querySelector('#curve-text-container');
    var path = document.querySelector( choosePath.getAttribute('href') );
    var pathLength = path.getTotalLength();

    const percentageSeen = (element) => {
        // Get the relevant measurements and positions
        const viewportHeight = window.innerHeight;
        const scrollTop = window.scrollY;
        const elementOffsetTop = element.offsetTop;
        const elementHeight = element.offsetHeight;
      
        // Calculate percentage of the element that's been seen
        const distance = scrollTop + viewportHeight - elementOffsetTop;
        const percentage = Math.round(
          distance / ((viewportHeight + elementHeight) / 100)
        );
      
        // Restrict the range to between 0 and 100
        return Math.min(100, Math.max(0, percentage));
      };

    document.addEventListener('DOMContentLoaded', () => {
        fetch('/ajax/videoembed').then(function (response) {
            return response.text();
        }).then(function (html) {
            videoWrapper.innerHTML = html;
            videoWrapper.classList.add('loaded');
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    });

    // console.log(pathLength);

    function updatePathOffset(offset){
        if (offset > 515) {
            choosePath.setAttribute('startOffset', -1*(offset-650)); 
            happyPath.setAttribute('startOffset', offset);     
        }
    }

    updatePathOffset(pathLength);

    function animateOverlayText(){
        requestAnimationFrame(function(){
            var rect = textContainer.getBoundingClientRect();
            var scrollPercent = rect.y / window.innerHeight;
            updatePathOffset( (scrollPercent * 2 * pathLength) - 120 );
        });
    }

    window.addEventListener('scroll', function(){
        let videoElementPosition = videoElement.getBoundingClientRect();
        if (videoElementPosition.y < 120) {
            if (videoWrapperWidth < 100) {
                videoWrapperWidth = percentageSeen(videoElement);
            }
            videoWrapper.style.width = `${videoWrapperWidth}%`;
        }
        animateOverlayText();
    });

    // window.addEventListener('DOMContentLoaded',() => {
    //     animateHero();
    // });



    // Custom Cursor Functionality
    if (is_touch_enabled()) {
        document.getElementById('mobile-play-button').classList.add('active');
    } else {
        videoElement.addEventListener('mouseover', (e) => {
            cursor.style.display = 'flex';

            videoElement.addEventListener('mousemove', function(e){
            var x = e.clientX;
            var y = e.clientY;
            cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
            if(e.clientX < (window.innerWidth / 2)) {
                cursor.classList.add('left');
            } else {
                cursor.classList.remove('left');
            }

            //   if(e.target.classList.contains('btn')) {
            //     cursor.classList.add('btn-hover');
            //   } else {
            //     cursor.classList.remove('btn-hover');
            //   }
            });
            
        });

        videoElement.addEventListener('mouseleave', () => {
            cursor.style.display = 'none';
        });
    }


    // Play the full video
    videoElement.addEventListener('click', (e) => {
        document.getElementById('full-video-wrapper').classList.add('active');
        document.getElementById('video-inner-wrapper').innerHTML = '<iframe src="https://player.vimeo.com/video/871626195?h=05ed662b1a&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479" width="3840" height="2160" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Choose Happy"></iframe>';
    });

    let closeVideo = document.getElementById('btn-close-video');
    closeVideo.addEventListener('click', (e) => {
        document.getElementById('full-video-wrapper').classList.remove('active');
        document.getElementById('video-inner-wrapper').innerHTML = '';
    });

}