// if(document.getElementsByClassName('farm-map').length > 0) {
//     let farmMap = document.querySelector('.farm-map');
//     let tooltip = document.createElement('div');
//     tooltip.classList.add('map-tooltip');
//     document.body.insertBefore(tooltip, farmMap);
//     document.querySelectorAll('.map-pin').forEach((pin) => {
//         pin.addEventListener('click', (e) => {
//             e.preventDefault();
//             e.stopPropagation();            

//             tooltip.style.display = 'block';
//             tooltip.style.left = `${e.clientX}px`;
//             tooltip.style.top = `${e.clientY}px`;
//             pin.classList.add('active');
//         });
//     });
// }