function isInViewport(el, tolerace) {
    const rect = el.getBoundingClientRect();

    let tolerance = tolerace || 0;

    let isinview = (
      rect.top >= tolerance &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );

    // returns true or false based on whether or not the element is in viewport
    return isinview; 
}

window.addEventListener('DOMContentLoaded',() => {
    const pageElements = document.querySelectorAll('[animate]');
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            entry.isIntersecting && entry.target.classList.add('in-view');
        });
    });
    pageElements.forEach(element => {
        observer.observe(element);
    });    
});


