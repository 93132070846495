if(document.getElementsByClassName('article-carousel').length > 0 && typeof Splide !== 'undefined') {

    var articleSlider = new Splide('.article-carousel', {
        type: 'slide',
        mediaQuery: 'min',
        arrows: false,
        height:'580px',
        pagination: false,
        updateOnMove: true,
        perPage: 2,
        perMove: 1,
        lazyLoad: true,
        breakpoints: {
            768: {
                perPage: 3,
            },
            1200: {
                perPage: 4,
            },
        }
    });
    
    articleSlider.mount();

}