let historyElement = document.querySelector('.history.element');
if (historyElement) {

    let historySlider = document.querySelector('.history-slider');
    let historyItems = historySlider.querySelectorAll('.history-item');
    let dottedLine = historySlider.querySelector('.dotted-line');

    historySlider.style.width = `${historyItems.length * 320}px`;
    dottedLine.style.width = `${(historyItems.length-1) * 320}px`;

    var historyCursor = document.querySelector('.history-cursor');

    historyElement.addEventListener('mouseover', (e) => {
        historyCursor.style.display = 'flex';

        historyElement.addEventListener('mousemove', function(e){
            var x = e.clientX;
            var y = e.clientY;
            historyCursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
            if(e.clientX < (window.innerWidth / 2)) {
              historyCursor.classList.add('left');
            } else {
              historyCursor.classList.remove('left');
            }
          });
    });
    historyElement.addEventListener('mouseleave', () => {
        historyCursor.style.display = 'none';
    });

    var historySwipe = new Hammer(historyElement);
    historySwipe.on('swiperight', function(ev) {
        let currentPos = parseInt(historySlider.dataset.slide);
        if (currentPos == 0) {
            return;
        }
        slideTrack(currentPos, 'prev');
    });
    historySwipe.on('swipeleft', function(ev) {
        let currentPos = parseInt(historySlider.dataset.slide);
        slideTrack(currentPos, 'next');
    });

    historyElement.addEventListener('click', (e) => {
        let currentPos = parseInt(historySlider.dataset.slide);
        if (historyCursor.classList.contains('left')) {
            if (currentPos == 0) {
                return;
            }
            slideTrack(currentPos, 'prev');
        } else {
            slideTrack(currentPos, 'next');
        }
    });

    function slideTrack(currentPos, direction) {
        let screenSize = window.innerWidth;
        let perSlide = 1;
        let newPos = 0;
        // if (screenSize < 1200 && screenSize > 992) {
        //     perSlide = 3;
        // } else if (screenSize < 992 && screenSize > 768) {
        //     perSlide = 2;
        // } else if (screenSize < 768) {
        //     perSlide = 1;
        // }

        if (direction == 'next') {
            if (screenSize > 1200 && currentPos == historyItems.length - 4) {
                return;
            } else if (screenSize > 992 && currentPos == historyItems.length - 2) {
                return;
            } else if (currentPos == historyItems.length - 1) {
                return;
            }
            newPos = ((perSlide*(currentPos+1))*320) * -1;
        } else {
            newPos = ((perSlide*(currentPos-1))*320) * -1;
            newPos = newPos > 0 ? 0 : newPos;
        }

        historySlider.style.transform = `translateX(${newPos}px)`;
        historySlider.dataset.slide = direction == 'next' ? currentPos + perSlide : currentPos - perSlide;

    }

}