if(document.getElementsByClassName('grid-tile-slider').length > 0 && typeof Splide !== 'undefined') {

    var tileSlider = new Splide('.grid-tile-slider', {
        type: 'slide',
        mediaQuery: 'min',
        arrows: false,
        pagination: true,
        updateOnMove: true,
        perPage: 1,
        perMove: 1,
        lazyLoad: true,
        breakpoints: {
            768: {
                destroy: true,
            },
        }
    });
    
    tileSlider.mount();
}