document.addEventListener("DOMContentLoaded", function () {
    let form = document.querySelector("#career-form");
    if (form) {
        let errorDisplay = form.querySelector(".error");
        let successContainer = document.querySelector(".success");
        let fileInput = form.querySelector("input[name=file]");
        let formContainer = form.querySelector(".formContainer");
        let sendMsgButton = document.querySelector("#send-msg-btn");
        let contactButton = document.querySelector("#cta-btn-contact-page");

        fileInput.addEventListener("change", () => {
            let file = fileInput.files[0];
            form.querySelector(".upload .file").textContent = file?.name ?? "No file chosen";
        });

        sendMsgButton?.addEventListener("click", () => {
            const formTitle = form.querySelector(".contact-title");

            const offset = 130; // Adjust this value to your preference
            const topPosition = formTitle.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth"
            });
        });
        contactButton?.addEventListener("click", () => {
            const formTitle = form.querySelector(".contact-title");

            const offset = 130; // Adjust this value to your preference
            const topPosition = formTitle.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth"
            });
        });



        form.addEventListener("submit", event => {
            event.preventDefault();
            event.stopPropagation();

            let data = new FormData(form);

            form.classList.add("locked");
            errorDisplay.classList.add("hidden");

            fetch(form.action, {
                method: "POST",
                body: data
            })
            .then(response => response.json())
            .then((payload) => {
                if (payload.success) {
                    formContainer.style.display = "none";
                    successContainer.style.display = "block";
                }
                else {
                    form.classList.remove("locked");
                    errorDisplay.textContent = payload.message;
                    errorDisplay.classList.remove("hidden");
                }
            });

            return false;
        });
    }
});