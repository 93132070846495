var navSlider = new Splide('.nav-products-splide', {
    type: 'slide',
    padding: '5rem',
    mediaQuery: 'min',
    arrows: false,
    pagination: true,
    updateOnMove: true,
    perPage: 1,
    perMove: 1,
    // breakpoints: {
    //       768: {
    //           destroy: true,
    //       },
    // }
});
  
navSlider.mount();