var heroVideo = document.getElementById('hero-video');

if (heroVideo !== null) {
    //add source to video tag
    function addSourceToVideo(element, src) {
        var source = document.createElement('source');
        source.src = src;
        source.type = 'video/mp4';
        element.appendChild(source);
        
    }

    //determine screen size and select mobile or desktop vid
    function whichSizeVideo(element, src) {
        var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
        if (windowWidth > 768 ) {
            addSourceToVideo( element, src.dataset.desktopVid);
            element.classList.add('desktop');
        } else {
            addSourceToVideo(element, src.dataset.mobileVid);
            element.classList.add('mobile');
        }
    }

    //init only if page has videos
    function videoSize() {
        whichSizeVideo(heroVideo, heroVideo);
    }
    videoSize();

    window.addEventListener('resize', function(event){
        var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
        if (windowWidth > 768 && heroVideo.classList.contains('mobile')) {
            heroVideo.getElementsByTagName('source')[0].src = heroVideo.dataset.desktopVid;
            heroVideo.classList.remove('mobile');
            heroVideo.classList.add('desktop');
        }
        if (windowWidth < 768 && heroVideo.classList.contains('desktop')) {
            heroVideo.getElementsByTagName('source')[0].src = heroVideo.dataset.mobileVid;
            heroVideo.classList.remove('desktop');
            heroVideo.classList.add('mobile');
        }
    });
}