if(document.getElementsByClassName('CouponPage').length > 0) {

    let dottedLine = document.querySelector('#dotted-line-wrap');
    let dottedLineMobile = document.querySelector('#dotted-line-wrap-mobile');

    const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
        const { top, left, bottom, right } = el.getBoundingClientRect();
        const { innerHeight, innerWidth } = window;
        return partiallyVisible
          ? ((top > 0 && top < innerHeight) ||
              (bottom > 0 && bottom < innerHeight)) &&
              ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
          : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
      };


        window.addEventListener('scroll',()=> {
            if (elementIsVisibleInViewport(dottedLine)) {
                dottedLine.classList.add('show');
                dottedLineMobile.classList.add('show');
            }
        });

}