import { CountUp } from '../countUp.min.js';

if(document.getElementsByClassName('StatsElement').length > 0) {
    document.querySelectorAll('.StatsElement').forEach(element => {
        element.querySelectorAll('.val').forEach(stat => {
            let from = stat.dataset.from;
            let to = stat.dataset.to;
            const options = {
                  startVal: from,
                  duration: 2,
                enableScrollSpy: true
                };
            let countUp = new CountUp(stat, to, options);
            if (!countUp.error) {
                countUp.start();
              } else {
                console.error(countUp.error);
              }
        });
    });

    
    // const statElements = document.querySelectorAll('.StatsElement');
    // const statObserver = new IntersectionObserver(entries => {
    //     entries.forEach(entry => {
    //         if (entry.isIntersecting) {
    //             entry.target.querySelectorAll('.val').forEach(stat => {
    //                 let from = stat.dataset.from;
    //                 let to = stat.dataset.to;
    //                 const options = {
    //                       startVal: from,
    //                       duration: 2,
    //                     };
    //                 let countUp = new CountUp(stat, to, options);
    //                 if (!countUp.error) {
    //                     countUp.start();
    //                   } else {
    //                     console.error(countUp.error);
    //                   }
    //             });        
    //         }
    //     });
    // });

    // statElements.forEach(element => {
    //     statObserver.observe(element);
    // });

}