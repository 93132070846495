if(document.getElementsByClassName('chicken-slider').length > 0 && typeof Splide !== 'undefined') {

    var largeSize = document.querySelector('.chicken-slider').classList.contains('three-wide') ? 3 : 4;
    var largeHeight = document.querySelector('.chicken-slider').classList.contains('three-wide') ? '700px' : '580px';

    var birdSplide = new Splide('.chicken-slider', {
        type: 'slide',
        mediaQuery: 'min',
        arrows: false,
        pagination: false,
        updateOnMove: true,
        perPage: 1,
        perMove: 1,
        lazyLoad: true,
        // padding: '5rem',
        // autoWidth:true,
        height:'530px',
        breakpoints: {
            750: {
                perPage: 2,
                height: largeHeight,
            },
            1200: {
                perPage: 3,
            },
            1600: {
                perPage: largeSize,
            },
        }
    });

    birdSplide.mount();

    document.querySelectorAll('.chicken-card').forEach((slide) => {
        slide.addEventListener('click', (event) => {
            let card = event.target.closest('.chicken-card');
            card.classList.toggle('flipped');
        });
    });

    // var birdCarousel = document.querySelector('.product-carousel');
    // var cursor = document.querySelector('.cursor');
    
    // birdCarousel.addEventListener('mouseover', (e) => {
    //     cursor.style.display = 'flex';

    //     birdCarousel.addEventListener('mousemove', function(e){
    //       var x = e.clientX;
    //       var y = e.clientY;
    //       cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
    //       if(e.clientX < (window.innerWidth / 2)) {
    //         cursor.classList.add('left');
    //       } else {
    //         cursor.classList.remove('left');
    //       }

    //       if(e.target.classList.contains('btn')) {
    //         cursor.classList.add('btn-hover');
    //       } else {
    //         cursor.classList.remove('btn-hover');
    //       }
    //     });
        
    // });

    // birdCarousel.addEventListener('mouseleave', () => {
    //     cursor.style.display = 'none';
    // });
    

    // birdCarousel.addEventListener('click', (e) => {
    //     var cursor = document.querySelector('.cursor');

    //     if (cursor.classList.contains('left')) {
    //         productSplide.go( '<' );
    //     } else {
    //         productSplide.go( '>' );
    //     }
    // });


}